import React from "react"
import { useCourseStore } from "../../../../../store/courses"
import LayoutCourse from "../../../../../layouts/course"
import Seo from "../../../../../components/seo"
import Heading from "../../../../../components/heading"
import Stack from "../../../../../components/stack"
import Box from "../../../../../components/box"
import CourseFooterBool from "../../../../../components/course-footer-bool"
import Poem from "../../../../../components/poem"
import { getCourseNavigation } from "../../../../../store/courses"

const Page = () => {
  const { setAnswer } = useCourseStore()
  const navigation = getCourseNavigation({ courseId: "versteckte-ordnung" })

  return (
    <LayoutCourse
      footer={
        <CourseFooterBool
          title="Ist das ein Gedicht?"
          to="/kurse/versteckte-ordnung/01-gedicht-erkennen/maisonntag/loesung"
          onSubmit={(submission) => {
            setAnswer({
              courseId: "versteckte-ordnung",
              chapterId: "01-gedicht-erkennen",
              taskId: "maisonntag",
              answer: submission,
            })
          }}
        />
      }
      navigation={navigation}
    >
      <Seo title="Maisonntag" />
      <Box>
        <Stack>
          <Heading as="h2" level={2}>
            Maisonntag
          </Heading>
          <Poem>
            <span>Du klare Luft, du liebe Sonne,</span>
            <span>Du grüner Wald, du Blütental</span>
            <span>Du ganze große Maienwonne,</span>
            <span>sei mir gegrüßt viel tausendmal.</span>
            <br />
            <span>Wie regungslos ob deiner Schöne</span>
            <span>Hemmt seinen Lauf der Morgenwind,</span>
            <span>Und Vogelsang und Glockentöne</span>
            <span>Nur in der Luft lebendig sind.</span>
          </Poem>
        </Stack>
      </Box>
    </LayoutCourse>
  )
}

export default Page
